<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            Hasznos alkalmazások a nyárra 
          </h2>
          <p class="hashText">- Hogy biztonságban élvezhesd a szünidőt</p>
        </b-col>
      </b-row>

      <b-row class="maintText__row">
        <b-col cols="12" lg="6" class="side-gap order-0">
          <div class="maintText__txt-content">
            <p class="text">
              Nyaralni a legtöbben azért megyünk, hogy kikapcsolódjunk, feltöltődjünk, új helyeket fedezzünk fel, esetleg azokkal legyünk, akiket szeretünk. Azonban egy vakáción több veszély leselkedik pénztárcánkra, személyes adatainkra, mint először gondolnánk. Szerencsére ma már hasznos tippek és app-ok tömkelege segít nekünk, hogy szembeszállhassunk ezekkel a nehézségekkel. Nézzünk meg pár olyan alkalmazást, amik segítenek, hogy a telefonon tárolt adataink és privát dolgaink biztonságban legyenek és a pihenés valóban pihenés legyen! 
            </p>
              
          </div>
        </b-col>
        <b-col cols="12" lg="6" class="side-gap order-4 order-lg-1">
            <img src="images/nyari-appok.png" class="w-100 margin-top-sm-44"/>
        </b-col>
        <b-col cols="12" lg="6" class="side-gap order-lg-2 order-1 pt-5">
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text"><b>Offline térkép:</b> Ha tudjuk az úticélunkat, de közben nem szeretnénk az adatforgalmunkat csökkenteni, vagy olyan helyre utazunk, ahol lassú az internet, vagy egyáltalán nincs is, érdemes a térképet offline módban használni. Ehhez nem kell mást tenni, mint előre lementeni a szükséges területet. Így kényelmesen utazhatunk anélkül, hogy mobilnetünktől függenénk. Arra azonban érdemes figyelni, hogy ilyen esetben csak az utolsó mentés verzióját láthatjuk.</p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text"><b>VPN, vagyis Virtual Private Network:</b> Fontos része az adatvédelemnek nyaralás során, és a hétköznapokban is hasznos társ lehet. A VPN megvéd minket a nyílt Wi-Fi hálózatokon is azáltal, hogy titkosított kapcsolatot biztosít a készülékünk és az internet között.</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text"><b>Biztonságos szörfözés:</b> Vannak böngészők, amelyek extra védelmet nyújtanak számunkra például beépített hirdetésblokkolóval, nyomkövetési védelemmel, vagy az adataink törlésével a böngészés után. </p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">4</span>
                  <p class="text"><b>Távoli adatvédelem:</b> Ezek az alkalmazások akkor nyújtanak segítséget, ha már megtörtént a baj. Ha elhagytuk, vagy ellopták telefonunkat, akkor egy ilyen alkalmazás segítségével lehetőségünk nyílik készülékünk távoli helymeghatározására, zárolására vagy a rajta lévő adatok törlésére.</p>
                </li>
              </ul>
            </p>
        </b-col>
        <b-col cols="12" lg="6" class="side-gap order-lg-3 order-2 pt-5">
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">4</span>
                  <p class="text"><b>Alkalmazások védelme:</b> A komplett telefonunkat leszámítva talán eszünkbe sem jut egy-egy alkalmazást is külön jelszóval, vagy akár ujjlenyomat-azonosítással védeni. Pedig ez az extra biztonsági lépés is hasznos lehet az adathalászokkal szemben. Bizonyos applikációk segítségével az általad kiválasztott app-ok, vagy akár a telefonodon lévő teljes alkalmazáslista védhető.</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">5</span>
                  <p class="text"><b>Titkosított üzenetek:</b> Létezik több olyan alkalmazás, amely végponttól végpontig titkosítja az elküldött üzeneteket. Ezek az appok segítenek, hogy a küldött és kapott tartalmakat csak a feladó és a címzett láthassa.</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">6</span>
                  <p class="text"><b>Titkosított fájlok:</b> Érdemes letölteni egy olyan alkalmazást, amely fájlokat és mappákat titkosít a telefonunkon. Ennek segítségével egy biztonságos széfet hozhatunk létre készülékünkön, és oda elrejthetünk minden olyan dolgot, amihez csak mi akarunk hozzáférni.</p>
                </li>
              </ul>
            </p>
        </b-col>
        <b-col cols="12" lg="12" class="side-gap order-lg-4 order-4">
            <p class="text">Azt nem árt figyelembe venni, hogy ezek a módszerek és alkalmazások segítenek megvédeni adatainkat, de teljesen nem bízhatunk rájuk mindent. Fontos, hogy az app-ok mindig naprakészek legyenek, telefonunkat pedig jelszóval, vagy egyéb azonosítóval védjük. 100%-os védelem nem létezik, így ezektől függetlenül nekünk is figyelnünk kell értékeinkre. </p>
        </b-col>
      </b-row>
    </b-container>
  <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Hasznos alkalmazások a nyárra ", 
        desc: "Nyaralni a legtöbben azért megyünk, hogy kikapcsolódjunk, feltöltődjünk, új helyeket fedezzünk fel, esetleg azokkal legyünk, akiket szeretünk.", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Hasznos alkalmazások a nyárra ",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "Nyaralni a legtöbben azért megyünk, hogy kikapcsolódjunk, feltöltődjünk, új helyeket fedezzünk fel, esetleg azokkal legyünk, akiket szeretünk."        
    })
  }
}
</script>